<template>
  <div class="toggle-switch" @click="toggle">
    <span class="circle" :class="{ active: isActive }"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      this.$emit('input', this.isActive); // Emitting input event with the new value
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: Boolean // Using v-model, so value is a Boolean prop
  }
}
</script>

<style scoped>
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  background-color: hsl(45, 60%, 85%);
  border-radius: 24px;
  cursor: pointer;
}

.circle {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 2px;
  top: 2px;
  background-color: white;
  transition: transform 0.4s ease;
  border-radius: 50%;
}

.active.circle {
  background-color: hsl(45, 95%, 60%);
  transform: translateX(20px);
}
</style>
