<template>
    <button class="custom-button" @click="emitClick">
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
    name: 'CustomButton',
    methods: {
      emitClick() {
        this.$emit('clicked');
      }
    }
  }
  </script>
  
  <style scoped>
  .custom-button {
    background-color: hsl(45, 95%, 60%);
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    width: 100%;

    font-weight: 400;

    font-size: 1rem;
    line-height: 1.5rem;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .custom-button:hover {
    background-color: hsl(45, 95%, 50%);
  }
  
  .custom-button:active {
    background-color: hsl(45, 95%, 40%);
  }
  
  .custom-button:disabled {
    background-color: hsl(45, 95%, 70%);
    cursor: not-allowed;
  }
  </style>
  