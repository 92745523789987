<template>
  <div id="app">
    <TopBar title="Yummeh" />
    <SectionImage
      :imageSrc="require('./assets/warm-kitchen.png')"
      imageDescription="A warm, inviting kitchen with wheat-themed accents"
    />
    <SuggestionTitle :title="suggestionTitle" />
    <SuggestionButton @clicked="getRandomMeal">Meh!</SuggestionButton>
    <FormToggleSwitchGroup
      :optionsList="optionsList"
      v-model:isVegetarian="isVegetarian"
      v-model:isDessert="isDessert"
      v-model:isLowSugar="isLowSugar"
    />
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue'
import SectionImage from './components/SectionImage.vue'
import SuggestionTitle from './components/SuggestionTitle.vue'
import SuggestionButton from './components/SuggestionButton.vue'
import FormToggleSwitchGroup from './components/FormToggleSwitchGroup.vue'
import { meals } from './meals.js'; // Import the meals array

export default {
  name: 'App',
  components: {
    TopBar,
    SectionImage,
    SuggestionTitle,
    SuggestionButton,
    FormToggleSwitchGroup
  },
  data() {
    return {
      suggestionTitle: 'Recommended for You',
      optionsList: ['Vegetarian', 'Dessert', 'Low sugar'],
      meals: meals,
      isVegetarian: false,
      isDessert: false,
      isLowSugar: false
    };
  },
  methods: {
    getRandomMeal() {
      // Filter meals based on the toggle switch criteria
      let filteredMeals = this.meals
        .filter(meal => !this.isVegetarian || meal.vegetarian)
        .filter(meal => this.isDessert == meal.dessert)
        .filter(meal => !this.isLowSugar || meal.lowSugar);
      if (filteredMeals.length === 0) {
        this.suggestionTitle = "No matching meals found.";
      } else {
        let meal = ""
        do {
          const randomIndex = Math.floor(Math.random() * filteredMeals.length);
          meal = filteredMeals[randomIndex];

        } while (this.suggestionTitle == meal)
        this.suggestionTitle = `${meal.name}`;
      }
    }
  },
  mounted() {
    this.getRandomMeal();
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 2rem;
  background-color: hsl(45 56% 91%);
  align-items: center;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-top: 1rem;
  background-color: hsl(45 50% 96%);
  color: #2c3e50;
  border-radius: 0.75rem;


  max-width: 400px;
}
</style>
