<template>
    <div class="top-bar">
        <h1>{{ title }}</h1>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
}
.top-bar h1 {
    letter-spacing: -0.25px;
    font-weight: 700;
    text-align: center;
    flex-grow: 1;
}
</style>