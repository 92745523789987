<template>
    <div class="section-image">
      <img :src="imageSrc" :alt="imageDescription" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SectionImage',
    props: {
      imageDescription: {
        type: String,
        required: true
      },
      imageSrc: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .section-image {
    text-align: center;
  }
  
  .section-image img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
  
  </style>
  