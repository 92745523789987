<template>
    <div class="suggestion-button">
      <CustomButton @clicked="$emit('clicked')">
        <slot></slot>
      </CustomButton>
    </div>
  </template>

<script>
import CustomButton from './Button.vue'

export default {
    name: 'SuggestionButton',
    components: {
        CustomButton
    }
}
</script>

<style scoped> 
.suggestion-button {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
</style>