export const meals = [
    { name: "Spaghetti Carbonara", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Chicken Parmesan", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Steak with Mashed Potatoes", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Grilled Salmon", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Vegetable Stir-Fry", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Shrimp Tacos", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Quinoa Salad", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Beef Stew", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Veggie Burger", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Margherita Pizza", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Chicken Caesar Salad", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Tomato Basil Soup", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Tofu Stir-Fry", vegetarian: true, dessert: false, lowSugar: false },
    { name: "BBQ Ribs", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Pasta Primavera", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Fish Tacos", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Caprese Salad", vegetarian: true, dessert: false, lowSugar: false },
    { name: "Beef Chili", vegetarian: false, dessert: false, lowSugar: false },
    { name: "Spaghetti Bolognese", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Vegetable Lasagna", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Chicken Alfredo", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Salmon with Roasted Vegetables", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Vegetarian Chili", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Beef Stir-Fry", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Eggplant Parmesan", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Teriyaki Tofu", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Pork Tenderloin with Apples", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Quinoa Stuffed Bell Peppers", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Beef and Broccoli", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Cauliflower Curry", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Lemon Herb Chicken", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Vegetable Pad Thai", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Pineapple Glazed Ham", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Mushroom Risotto", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Beef and Mushroom Stroganoff", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Sesame Ginger Salmon", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Vegetarian Pizza", vegetarian: true, dessert: false, lowSugar: true },
    { name: "BBQ Chicken", vegetarian: false, dessert: false, lowSugar: true },
    { name: "Vegetable Curry", vegetarian: true, dessert: false, lowSugar: true },
    { name: "Chocolate Cake", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Apple Pie", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Ice Cream Sundae", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Cheesecake", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Brownies", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Pumpkin Pie", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Tiramisu", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Creme Brulee", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Banana Split", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Lemon Meringue Pie", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Chocolate Mousse", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Strawberry Shortcake", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Key Lime Pie", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Peach Cobbler", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Fruit Tart", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Raspberry Sorbet", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Chocolate Chip Cookies", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Vanilla Pudding", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Red Velvet Cupcakes", vegetarian: true, dessert: true, lowSugar: false },
    { name: "Cinnamon Rolls", vegetarian: true, dessert: true, lowSugar: false },
];
