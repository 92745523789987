<template>
    <div class="suggestion-title">
      <h2>{{ title }}</h2>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SuggestionTitle',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .suggestion-title {
    margin: 1rem 0;
    text-align: center;
  }
  
  .suggestion-title h2 {
    font-size: 1.5rem;
    color: #333;
  }
  </style>
  