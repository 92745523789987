<template>
  <div class="form-toggle-switch">
    <label>{{ label }}</label>
    <div class="toggle-switch-container">
      <ToggleSwitch v-model="isActive" @input="toggleSwitch" />
    </div>
  </div>
</template>

<script>
import ToggleSwitch from './ToggleSwitch.vue';

export default {
  components: {
    ToggleSwitch
  },
  props: {
    label: String,
    value: Boolean
  },
  computed: {
    isActive: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    toggleSwitch(newValue) {
      this.$emit('input', newValue);
    }
  }
}
</script>

<style scoped>
.form-toggle-switch {
  display: flex;
  align-items: center;
  width: 100%;
  border-color: hsl(45 60% 85%);
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  border-bottom-width: 1px;
  padding: 1rem;
}

.form-toggle-switch label {
  margin-right: 10px;
}

.toggle-switch-container {
  flex-grow: 1;
  display: flex;
  justify-content: end;
  align-items: center
}
</style>
