<template>
  <div class="form-toggle-switch-group-container">
    <div class="form-toggle-switch-group">
      <div v-for="(option, index) in optionsList" :key="index">
        <FormToggleSwitch :label="option" :value="getValue(option)" @input="updateOption(option, $event)" />
      </div>
    </div>
  </div>
</template>

<script>
import FormToggleSwitch from './FormToggleSwitch.vue';

export default {
  components: {
    FormToggleSwitch
  },
  props: {
    optionsList: Array,
    isVegetarian: Boolean,
    isDessert: Boolean,
    isLowSugar: Boolean
  },
  methods: {
    getValue(option) {
      switch (option) {
        case 'Vegetarian':
          return this.isVegetarian;
        case 'Dessert':
          return this.isDessert;
        case 'Low sugar':
          return this.isLowSugar;
        default:
          return false;
      }
    },
    updateOption(option, value) {
      switch (option) {
        case 'Vegetarian':
          this.$emit('update:isVegetarian', value);
          break;
        case 'Dessert':
          this.$emit('update:isDessert', value);
          break;
        case 'Low sugar':
          this.$emit('update:isLowSugar', value);
          break;
      }
    }
  }
}
</script>

<style scoped>
.form-toggle-switch-group-container {

  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-toggle-switch-group {
  flex-direction: column;
  display: flex;
  border-color: hsl(45 60% 85%);
  border-width: 1px;
  border-radius: 0.75rem;
  border-style: solid;
}
</style>
